import { useId } from "react";
import { Tooltip } from "react-tooltip";
import styled from "styled-components";

const Tag = styled.div`
  background-color: var(--borderColor);
  color: var(--gray500);
  padding: 2px 8px;
  border-radius: 4px;
  margin-right: 8px;
  font-size: 12px;
  font-weight: 500;
  user-select: none;
`;

const TagsContainer = styled.div`
  display: flex;
`;

const TooltipItem = styled.p`
  margin: 0;
  font-size: 11px;
`;

interface TagItem {
  label: string;
  id: string;
}

interface BasicTagsProps {
  items: TagItem[];
  limit?: number;
}

export const BasicTags = (props: BasicTagsProps) => {
  const { items, limit } = props;
  const visibleItems = limit !== undefined ? items.slice(0, limit) : items;
  const hiddenItems = limit !== undefined ? items.slice(limit) : [];
  const id = useId();

  return (
    <TagsContainer>
      {visibleItems.map(({ id, label }) => (
        <Tag key={id}>
          {label}
        </Tag>
      ))}
      {hiddenItems.length > 0 && (
        <>
          <Tooltip id={id}>
            {hiddenItems.map(({ label, id }) => (
                <TooltipItem key={id}>
                  {label}
                </TooltipItem>
            ))}
          </Tooltip>
          <Tag data-tooltip-id={id} key="hidden-items">
            +{hiddenItems.length}
          </Tag>
        </>
      )}
    </TagsContainer>
  );
}