import React, { useState } from "react";
import { TaskProps } from "./types";
import { Header, UiFieldMappingItem } from "../MeetingTabSalesforce/FieldMappingContent";
import { Button, FlexColumn10, FlexEnd10, Title } from "../../../theme";
import { FieldMappingInput, HeaderRow, ObjectName } from "./style";
import { SearchObjectDialog } from "./SearchObject";
import { Item } from "../MeetingTabSalesforce/style";
import EditableContentTextarea from "../../forms/EditableContentTextarea";
import formEntriesToBody from "../../../utils/formEntriesToBody";
import LanguageBox from "../LanguageBox";
import { ThreeDots } from "react-loader-spinner";
import { useTranslation } from "react-i18next";

type ResetFieldParams = Record<string, { suggested_value: "", approved_at: "", confirmed_value: "" }>

export default function ReviewFieldMappings(props: TaskProps & { readOnly?: boolean }): JSX.Element {
  const { workflowRun, updateWorkflowRun, readOnly } = props;
  const { objectType } = workflowRun;
  const [currentFieldMapping, setCurrentFieldMapping] = useState<UiFieldMappingItem>();
  const [isRefreshingFields, setIsRefreshingFields] = useState(false);
  const { t } = useTranslation()

  const handleLanguageChange = async (language?: string) => {
    if (!language) return;

    setIsRefreshingFields(true)
    try {
      const body = workflowRun.fieldMappings.reduce<ResetFieldParams>((obj, fm) => {
        obj[fm.id] = { suggested_value: "", approved_at: "", confirmed_value: "" }
        return obj
      }, {});

      await updateWorkflowRun({ field_mapping_values: body, language });
    } finally {
      setIsRefreshingFields(false)
    }
  }

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    const data = formEntriesToBody(new FormData(form));
    const id = data.id;

    if (typeof id !== 'string') throw new Error('Missing field mapping id');

    updateWorkflowRun({ field_mapping_values: { [id]: data } });
  }
  return <FlexColumn10>
    <HeaderRow>
      <Title>{t('worflow_run.review-field-mapping.title', "Review {{objectType}}", { objectType })}</Title>
      {!readOnly && (
        <LanguageBox handleGenerateSummary={handleLanguageChange} userIsPaid />
      )}
      <ObjectName>{workflowRun.objectName}</ObjectName>
      {!readOnly && (
        <SearchObjectDialog {...props} />
      )}
    </HeaderRow>
    {workflowRun.fieldMappings.map((fieldMapping) => {
      const isOpen = currentFieldMapping?.id === fieldMapping.id
      const disabled = readOnly || fieldMapping.status === 'approved' || fieldMapping.status === 'ignored';
      const ignoreFormId = `ignoreField-${fieldMapping.id}`;
      return <Item key={`fieldMappingItem-${fieldMapping.id}`} $isActive={isOpen}>
        <Header
          fieldMapping={fieldMapping}
          onClick={() => setCurrentFieldMapping(isOpen ? undefined : fieldMapping)}
          isOpen={isOpen}
          isLoading={isRefreshingFields}
        />
        <div style={{ display: isOpen ? 'block' : 'none' }}>
          <form id={ignoreFormId} onSubmit={handleSubmit} style={{ display: "none" }}>
            <input type="hidden" name="id" value={fieldMapping.id} />
            <input type="hidden" name="disabled" value="true" />
            <input type="hidden" name="approved_at" value="" />
          </form>
          <FlexColumn10 as="form" onSubmit={handleSubmit}>
            <input type="hidden" name="id" value={fieldMapping.id} />
            <input type="hidden" name="disabled" value="" />
            <input type="hidden" name="approved_at" value={Date.now()} />
            <FieldMappingInput as="div" readOnly={disabled}>
              {isRefreshingFields ? (
                <ThreeDots width={20} height={10} color="var(--primary600)" />
              ) : (
                <EditableContentTextarea
                  key={`${workflowRun.id}-${fieldMapping.id}`}
                  autoFocus
                  placeholder={fieldMapping.label}
                  name="confirmed_value"
                  defaultValue={fieldMapping.confirmedValue || fieldMapping.suggestedValue}
                  readOnly={disabled}
                />
              )}
            </FieldMappingInput>
            {!readOnly && (
              <FlexEnd10>
                {fieldMapping.status !== 'ignored' && (
                  <Button small outline form={ignoreFormId} disabled={isRefreshingFields}>
                    {t("workflow_run.review-field-mapping.ignore", "Ignore")}
                  </Button>
                )}
                {fieldMapping.status !== 'approved' && (
                  <Button small disabled={isRefreshingFields}>
                    {t("workflow_run.review-field-mapping.approve", "Approve")}
                  </Button>
                )}
              </FlexEnd10>
            )}
          </FlexColumn10>
        </div>
      </Item>
    })}
  </FlexColumn10>
}

