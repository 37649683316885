import { useState, useMemo, useEffect } from 'react';
import uniqBy from 'lodash.uniqby';
import {
  groupConsecutiveByDeviceId, getTranscriptionAsText, highlightText
} from '../../utils/genericUtils';
import { MeetingData } from './Types';
import styled from 'styled-components';
import { Body } from '../../theme';
import CopyToClipboard from '../Reusable/CopyToClipboard';
import AttendeeAvatar from '../Reusable/Attendees';
import { useTranslation } from 'react-i18next';
import { MicrophoneIcon, TranslateIcon } from '../Icons';
import { Tooltip } from 'react-tooltip';
import { useUserData } from '../../components/hooks/useUserData';
import { getLanguagesFromMeeting, getUntranslatedTranscriptAsBlocks, getUntranslatedTranscriptAsText } from '../../utils/translatedMeeting';
import { BasicTags } from '../Reusable/BasicTags';
import { getLanguageNameWithoutRegion } from '../../utils/intl';

interface Person {
  name: string,
  deviceColor: string,
}

const Container = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;

const Left = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 13px;
`;

const Card = styled.div`
  border: var(--standardBorder);
  border-radius: var(--standardRadius);
  padding: 16px;
  background: var(--gray200);
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const CopyWrapper = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
`;

const BlockHeading = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-bottom: 0.5rem;
  min-height: 1.4rem;
`

const Person = styled.h6`
  font-weight: 700;
  font-size: 12px;
  margin: 0;
`;

const Right = styled(Left)`
  min-width: min(25%, 283px);
  max-width: min(25%, 283px);
  gap: 16px;

`;

const Attendee = styled.div`
  border: var(--standardBorder);
  border-radius: var(--standardRadius);
  background: var(--gray200);
  padding: 5px;
  padding-left: 15px;
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 700;
  font-size: 14px;
`;

const ToggleTranslateContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  color: var(--gray500);
  font-size: 14px;
  margin-right: 0.5rem;
  min-height: 19px;
  user-select: none;
`;

const TranscriptLabel = styled.span`
  margin-left: 0;
  font-size: 15px;
  margin-right: auto;
  color: var(--gray800);
  font-weight: 600;
`;

interface Props {
  meetingData: MeetingData;
  searchWords?: [string];
}


export default function MeetingTabTranscript({ meetingData, searchWords }: Props) {
  const { t, i18n } = useTranslation();

  const [userData] = useUserData();
  const { briefly_speech_engine: usesSpeechEngine } = userData.features;
  const speechEngineTranscript = useMemo(() => meetingData.messages ? getUntranslatedTranscriptAsBlocks(meetingData.messages) : undefined, [meetingData.messages, meetingData.id]);
  const [showTranslated, setShowTranslated] = useState(true);
  const overallMeetingLanguages = meetingData.messages ? getLanguagesFromMeeting(meetingData.messages) : undefined;

  const { attendeesByColor, deviceNamesById, transcriptionBlocks } = meetingData;

  const groupedTranscriptionBlocks = useMemo(() => transcriptionBlocks ? groupConsecutiveByDeviceId(transcriptionBlocks) : [], [transcriptionBlocks]);

  const [scrollExecutedForMeetingIdAndSearchWords, setScrollExecutedForMeetingIdAndSearchWords] = useState<[null | string, null | [string]]>([null, null]);

  useEffect(() => {
    if (searchWords) {
      if (JSON.stringify(scrollExecutedForMeetingIdAndSearchWords) === JSON.stringify([meetingData.meetingId, searchWords])) {
        return;
      }
      setScrollExecutedForMeetingIdAndSearchWords([meetingData.meetingId, searchWords]);
      setTimeout(() => {
        const scrollTo = document.querySelector("#transcript-container .highlight");
        if (scrollTo) {
          scrollTo.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }, 100);
    }
  }, [meetingData.meetingId, searchWords]);

  return (
    <Container>
      <Left>
        <ToggleTranslateContainer>
          <TranscriptLabel>
            {!speechEngineTranscript ? 'Transcript' : showTranslated ? 'Translated Transcript' : 'Spoken Transcript'}
          </TranscriptLabel>
          { usesSpeechEngine && speechEngineTranscript && <>
              <Tooltip id="original" place="top">
                Spoken Transcript
              </Tooltip>
              <MicrophoneIcon width={18} height={18} style={{ color: !showTranslated ? 'var(--brand-primary)' : 'inherit' }} data-tooltip-id="original"/>
              <label className='switch'>
                <input checked={showTranslated} type='checkbox' onChange={(e) => setShowTranslated(e.currentTarget.checked)} />
                <span className='slider round'></span>
              </label>
              <TranslateIcon width={18} height={18} style={{ color: showTranslated ? 'var(--brand-primary)' : 'inherit' }} data-tooltip-id="translated" />
              <Tooltip id="translated" place="top">
                Translated Transcript
              </Tooltip>
            </>
          }
        </ToggleTranslateContainer>
        <Card>
          <CopyWrapper>
            <CopyToClipboard size={18} text={!showTranslated && meetingData.messages ? getUntranslatedTranscriptAsText(meetingData.messages) : getTranscriptionAsText(meetingData)} />
          </CopyWrapper>
          { showTranslated && groupedTranscriptionBlocks.map(({ deviceId, blocks }) => {
            const deviceName = deviceNamesById[deviceId];
            const deviceColor = attendeesByColor.find((a) => a.name === deviceName)?.deviceColor;

            return <div key={blocks[0].messageId}>
              { deviceName !== "undefined" && <BlockHeading>
                <Person style={{ color: deviceColor }}>{deviceName}</Person>
              </BlockHeading> }
              <Body>
                {blocks.map(x => x.text).join(' ').split('\n').map((x, i) =>
                  <span key={`${x}-${i}`}>
                    {searchWords ? highlightText(x, searchWords, { background: 'var(--highlight)', color: 'black' }) : x}
                  </span>)}
              </Body>
            </div>;
          })}
          { !showTranslated && speechEngineTranscript && speechEngineTranscript.map(({ groupId, speaker, text, languagesSpoken }) => {
            const deviceColor = attendeesByColor.find((a) => a.name === speaker?.name)?.deviceColor;
            return <div key={groupId}>
              <BlockHeading>
                {speaker && <Person style={{ color: deviceColor }}>{speaker.name}</Person>}
                <BasicTags
                  limit={3}
                  items={languagesSpoken.map(lang => ({
                    id: lang,
                    label: getLanguageNameWithoutRegion(lang, i18n.language)
                  }))} />
              </BlockHeading>
              <Body>
                {text.join(' ').split('\n').map((x, i) =>
                  <span key={`${x}-${i}`}>
                    {searchWords ? highlightText(x, searchWords, { background: 'var(--highlight)', color: 'black' }) : x}
                  </span>)}
              </Body>
            </div>;
          })}
        </Card>
      </Left>
      {attendeesByColor.length > 0 &&
        <Right>
          <span style={{ fontWeight: 600, fontSize: "12px" }}>{t('meeting.transcript.speakers', 'Speakers:')}</span>
          {uniqBy(attendeesByColor, 'name').map((person: Person) => {
            const personLangs = overallMeetingLanguages?.languagesPerSpeaker[person.name]
            return (
              <Attendee key={`transcript-${person.name}-wrapper`}>
                <AttendeeAvatar attendeesByColor={[person]} keyId="transcript" />
                {person.name}
                {personLangs &&
                  <BasicTags limit={1} items={personLangs.map((lang) => ({
                    id: lang,
                    label: getLanguageNameWithoutRegion(lang, i18n.language)
                  }))} />}
              </Attendee>
            );
          })}
        </Right>}
    </Container>
  );
}
