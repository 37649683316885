import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import GrowableTextArea from "../Reusable/GrowableTextArea";

interface Props extends React.HTMLProps<HTMLTextAreaElement> {}

const StyledTextArea = styled(GrowableTextArea)`
  margin: 0;
  white-space: pre;
  text-wrap: pretty;
  outline: none;
  max-height: 25rem;
  overflow-y: auto;

  & > textarea {
    background-color: transparent;
  }

  & > textarea[readonly] {
    cursor: default;
    color: var(--gray600);
  }
`;

export default function EditableContentTextarea({ name, readOnly, ...props }: Props): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);
  const [value, setValue] = useState(props.defaultValue as string || '');

  const handleInput: React.FormEventHandler<HTMLTextAreaElement> = (e) => {
    setValue(e.currentTarget.value);
  };

  useEffect(() => {
    if (!readOnly && props.autoFocus && ref.current) {
      ref.current.focus();
    }
  }, [props.autoFocus]);

  return <StyledTextArea textareaProps={{name, readOnly, ...props}} value={value} onChange={handleInput} />
}
