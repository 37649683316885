export const getLanguageNameWithoutRegion = (languageCode: string, locale: Intl.LocalesArgument = 'en-US'): string => {
  const noRegionLanguageCode = languageCode.split('-')[0];
  const displayNames = new Intl.DisplayNames(
    locale,
    { type: 'language', languageDisplay: 'dialect' }
  );

  const displayName = displayNames.of(noRegionLanguageCode);

  if (displayName) {
    return displayName.charAt(0).toUpperCase() + displayName.slice(1);
  }

  return languageCode;
};
