/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Flex } from '../../theme';
import { checkIfStandardSummaryIsEligible, copyToClipboard, generateSummary, getTranscriptionAsText, getUserAccessToken, trackEvent } from '../../utils/genericUtils';
import { CheckmarkCircleIcon, CopyIcon } from '../Icons';
import ButtonWithTimeoutReplaceOnClick from '../Reusable/ButtonWithTimeoutReplaceOnClick';
import AIRocket from './AIRocket';
import LanguageBox from './LanguageBox';
import SendNotifications from './SendNotifications';

import useDataFromEndpoint from '../hooks/useDataFromEndpoint';
import LiveChecklistSummaries from './LiveChecklistSummaries';
import SummarySection from './SummarySection';

import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Sparkles from '../Icons/Sparkles';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
`;

const BorderedBox = styled(Flex)`
  border: var(--standardBorder);
  border-radius: var(--standardRadius);
  padding: 15px;
`;

const ActionBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 36px;
  align-items: center;
  flex-wrap: wrap;
  gap: 25px;
`;
const Actions = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;


const getTranslatedColumns = (t) => [
  [
    {
      label: `${t('meeting.ia-summary.call-summary.title', 'Call summary')}`,
      key: 'short_summary'
    },
  ],
  [
    {
      label: `${t('meeting.ia-summary.key-insights.title', 'Key insights')}`,
      key: 'key_insights'
    }
  ],
  [
    {
      label: `${t('meeting.ia-summary.bullet-summary.title', 'Bullet summary')}`,
      key: 'long_summary'
    }
  ],
  [
    {
      label: `${t('meeting.ia-summary.action-items.title', 'Action items')}`,
      key: 'action_items'
    }
  ],
  [
    {
      label: `${t('meeting.ia-summary.notes.title', 'Notes')}`,
      key: 'notes'
    }
  ]
];

const MAX_STORED_SUMMARIES = 1000;

const NotReady = ({
  aiSummaryCostsAiCredit,
  isGenerating,
  handleGenerateSummary,
  meetingSummaryWasRequestedBefore,
  t,
}) => {
  return (
    <div className="generate-ai-summary" style={{ display: 'flex', justifyContent: 'center', marginTop: 60, flex: 1 }}>
      <div className="flexColumnCenter" style={{ gap: "12px" }}>
        <div className="flexColumnCenter">
          <AIRocket />
          <h5>{isGenerating ? t('meeting.ia-summary.generate.is-generating', 'Generating AI summary...') : t('meeting.ia-summary.generate.ready-to-generate', 'Your AI Summary is ready to be generated!')} </h5>
        </div>
        {
          !isGenerating &&
          <Button onClick={() => handleGenerateSummary()}>
            {meetingSummaryWasRequestedBefore ?
              t('meeting.ia-summary.generate.view-summary', 'View AI summary') :
              t('meeting.ia-summary.generate.generate-summary', 'Generate AI summary')}
            {' '}
            {aiSummaryCostsAiCredit ?
              t('meeting.ia-summary.generate.free-cost', '[1 AI Credit]') :
              ''}
          </Button>
        }
        {
          isGenerating && <div className="loader-container">
            <div className="loader-inner" />
          </div>
        }
      </div>

    </div>
  );
};

export default function MeetingTabSummary({ isBackend, userData, aiSummaryCostsAiCredit, meetingSummaryWasRequestedBefore, meetingData, backendMeetingData, setShowOutOfCreditsPopup, searchWords, loadBackendMeetingData }) {
  const { meetingId, noteBlocks: unfilteredNoteBlocks } = meetingData;
  const LOCAL_STORAGE_SUMMARY_KEY = 'summary-cache-' + meetingId;

  const [meetingSummary, setMeetingSummary] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const { t } = useTranslation();

  const SUMMARY_COLUMNS_CONFIG = getTranslatedColumns(t);
  const anyError = meetingSummary && SUMMARY_COLUMNS_CONFIG.find(items => items.find(x => meetingSummary?.[x.key]?.error));

  const [workflowRuns] = useDataFromEndpoint(`/api/workflow_runs`, {
    params: {
      transcriptId: meetingData.transcriptId,
      taskSet: 'live_checklist',
      includeFieldContext: true,
    },
    ready: meetingData.transcriptId != null,
  });

  const noteBlocks = useMemo(() => {
    if (unfilteredNoteBlocks == null) {
      return [];
    }
    const noteBlocks = unfilteredNoteBlocks.filter((note) => !note.match(/^[\s•]*$/));
    // Remove No Notes text that comes from backend so that we get the translated text.
    if (noteBlocks.length === 1 && noteBlocks[0].match((/^[\s• No notes]*$/))) {
      noteBlocks.pop();
    }

    if (noteBlocks.length === 0) {
      noteBlocks.push(t('meeting.ia-summary.note-block.no-notes', 'No Notes'));
    }

    return noteBlocks.map((note) => note.replace(" •", '').trim());
  }, [unfilteredNoteBlocks]);

  // Clearing latest local storage entries if is it going to be overloaded soon
  const isStorageOverloaded = () => {
    let count = 0;
    for (const key in localStorage) {
      if (key.includes('summary-cache-')) {
        count++;
      }
    }
    return count >= MAX_STORED_SUMMARIES;
  };

  const removeOldestSummary = () => {
    let oldestTimestamp = null;
    let oldestEntryKey = null;
    for (const key in localStorage) {
      if (key.includes('summary-cache-')) {
        const { timestamp } = JSON.parse(localStorage.getItem(key));
        if (!timestamp) {
          oldestEntryKey = key;
          break;
        }
        if (!oldestTimestamp || timestamp.localeCompare(oldestTimestamp) < 0) {
          oldestTimestamp = timestamp;
          oldestEntryKey = key;
        }
      }
    }
    localStorage.removeItem(oldestEntryKey);
  };

  const setMeetingSummaryInCache = (summaryData) => {
    localStorage.setItem(LOCAL_STORAGE_SUMMARY_KEY, JSON.stringify({
      ...summaryData,
      timestamp: new Date()
    }));
  };

  function handleGenerateSummary(language = null) {

    checkIfStandardSummaryIsEligible(getTranscriptionAsText(meetingData), isEligible => {
      if (isEligible) {
        setIsGenerating(true);

        getUserAccessToken(({ access }) => {


          trackEvent("briefly-create-generate-ai-summary", {
            meetingSource: meetingData.isBackend ? 'UPLOAD' : 'BRIEFLY_EXTENSION'
          });

          generateSummary(
            getTranscriptionAsText(meetingData), meetingData.transcriptId, language, access, summaryData => {
              setIsGenerating(false);

              summaryData.selectedLanguage = language;

              setMeetingSummary({
                ...summaryData, notes: `• ${noteBlocks?.join("\n• ")}`
              });

              if (isStorageOverloaded()) {
                removeOldestSummary();
              }

              setMeetingSummaryInCache({
                ...summaryData, notes: `• ${noteBlocks?.join("\n• ")}`
              });
            }
          );
        });
      } else {
        setShowOutOfCreditsPopup(true);
      }
    });
  }

  const handleCopyAll = () => {
    let concat = '';
    SUMMARY_COLUMNS_CONFIG.map(items => items.map(({ label, key }) => {
      concat += label + ':\n';
      concat += meetingSummary[key] + '\n\n';
    }));
    copyToClipboard(concat);
  };

  useEffect(() => {
    if (backendMeetingData.long_summary) {
      setMeetingSummaryInCache({
        short_summary: backendMeetingData.short_summary,
        long_summary: backendMeetingData.long_summary,
        key_insights: backendMeetingData.key_insights,
        action_items: backendMeetingData.action_items,
        notes: `• ${noteBlocks?.join("\n• ")}`,
      });

      setMeetingSummary({
        ...backendMeetingData,
        notes: `• ${noteBlocks?.join("\n• ")}`,
      });
    } else {
      setMeetingSummary(null);
    }
  }, [backendMeetingData]);

  const { admin_user, admin_user_subscription_is_active, subscription_is_active } = userData;
  const userIsPaid = (subscription_is_active || (!!admin_user && admin_user_subscription_is_active));
  return (
    <div>
      <ActionBar>
        <LanguageBox
          handleGenerateSummary={handleGenerateSummary}
          userIsPaid={userIsPaid}
        />
        <Actions>
          <SendNotifications
            backendMeetingData={backendMeetingData}
            loadBackendMeetingData={loadBackendMeetingData}
            meetingData={meetingData}
            userData={userData} />
          <ButtonWithTimeoutReplaceOnClick
            iconWithText
            disabled={!meetingSummary || isGenerating}
            initialText={<><CopyIcon />{t('meeting.ia-summary.generate.copy-clipboard', 'Copy entire AI summary')}</>}
            clickedText={<><CheckmarkCircleIcon size="14" />{t('meeting.ia-summary.generate.copied', 'Copied to clipboard')}</>}
            onClick={handleCopyAll}
          />
        </Actions>
      </ActionBar>
      {!meetingSummary || isGenerating ? (
        <NotReady aiSummaryCostsAiCredit={aiSummaryCostsAiCredit} isGenerating={isGenerating} handleGenerateSummary={handleGenerateSummary} meetingSummaryWasRequestedBefore={meetingSummaryWasRequestedBefore} t={t} />
      ) : (
        <div>
          <Container>
            {anyError && <div style={{ background: "var(--primary100)", padding: "12px", borderRadius: "var(--standardRadius)" }}>
              {t('meeting.ia-summary.generate.error.title', 'There was an issue with OpenAI while generating some AI summaries, try again later.')}{" "}
              <Button link onClick={() => handleGenerateSummary()}>{t('meeting.ia-summary.generate.error.retry', 'Try again')}</Button>
            </div>}
            {meetingData.transcriptId && (
              <BorderedBox $items="center" $gap={10}>
                <Link to={`/chat?transcriptIds=${meetingData.transcriptId}`} style={{ display: 'flex', alignItems: 'center' }}>
                  <Button small><Sparkles size={20} /> {t('meeting.ai-summary.start-chat.button', 'Start Chat')}</Button>
                </Link>
                {t('meeting.ai-summary.start-chat.text', 'Not finding what you\'re looking for? Start a chat with Briefly AI.')}
              </BorderedBox>
            )}

            {SUMMARY_COLUMNS_CONFIG.map((items) => (
              <div key={items.map((i) => i.key).join('||')} >
                {items.map(({ label, key }) => {
                  return <SummarySection
                    key={key}
                    keyIdentifier={key}
                    header={label}
                    text={meetingSummary[key]}
                    isBackend={isBackend}
                    searchWords={searchWords}
                    noteBlocks={noteBlocks}
                    attendeesByColor={meetingData.attendeesByColor}
                    transcriptId={meetingData.transcriptId}
                  />;
                })}
              </div>
            )
            )}
            {workflowRuns?.map((workflowRun) => (
              <LiveChecklistSummaries key={workflowRun.id} workflowRun={workflowRun} searchWords={searchWords} />
            ))}
          </Container >
        </div>
      )
      }
    </div >
  );
}
