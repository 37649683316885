import React, { ChangeEventHandler, FormEventHandler, useCallback, useEffect, useState, useMemo } from 'react';
import { apiGet } from '../../../utils/api';
import CreatableSelect from 'react-select/creatable';
import { ControlProps, SingleValue, components } from 'react-select';
import { ThreeDots } from 'react-loader-spinner';
import { FileUploadIcon, GoogleMeetLogo, SearchIcon, ZoomLogo, CalendarIcon, PersonCircle, SortArrow } from '../../Icons';
import { Button } from '../../../theme';
import { Centered, LoadingAnimation, GroupRow, Label, LinkButton, Container, SearchRow, MeetingList, Row, FloatingTriggerButton, CloseButton } from './styles';
import { Screen, SortOrder, Meeting, State, Participant, MeetingGroup, MeetingFilterProps, Option, Mode } from './types';
import MeetingRow from './MeetingRow';
import { useTranslation } from 'react-i18next';
import Select from '../../forms/Select';
import Dialog from '../Dialog';
import X from '../../Icons/X';

const DAYS = 60 * 60 * 24;
const LIMIT = 300;
const orderOptions: { label: string, value: SortOrder, translationSubKey: string; }[] = [
  { label: "Newest", value: '-meeting_start_date_time', translationSubKey: 'newest' },
  { label: "Oldest", value: 'meeting_start_date_time', translationSubKey: 'oldest' },
  { label: "Most Relevant", value: '-rank', translationSubKey: 'most-relevant' },
];

interface NumberOption {
  label: string,
  value: number,
}

const dateOptions: NumberOption[] = [
  { label: "All Time", value: -1 },
  { label: "1 Day", value: 1 * DAYS },
  { label: "7 Days", value: 7 * DAYS },
  { label: "14 Days", value: 14 * DAYS },
  { label: "30 Days", value: 30 * DAYS },
];

export function getMeetingIcon(platform: string, size?: string): JSX.Element {
  if (platform === 'zoom') {
    return <ZoomLogo width={size} />;
  }
  if (platform == 'Google Meets') {
    return <GoogleMeetLogo width={size} />;
  }

  return <FileUploadIcon width={size} />;
}

export default function MeetingFilter(props: MeetingFilterProps): JSX.Element {
  const { onConfirm, chatConnectedMeetings, onClose, open, onOpenChange } = props;
  const transcriptCount = chatConnectedMeetings.length;
  const initialMode: Mode = transcriptCount === 0 ? 'create' : 'update';
  const mode = initialMode;

  const { t, i18n } = useTranslation();

  const translatedDateOptions = useMemo(() => dateOptions.map(({ label, value }) => {
    if (value === -1) {
      return { label: t('chat.meeting-selector.time.placeholder', 'All Time'), value };
    }
    const translatedLabel = t('chat.meeting-selector.time.label', {
      count: value / DAYS,
      defaultValue: label
    });

    return { label: translatedLabel, value };
  }), [t]);

  const translatedOrderOptions = useMemo(() => orderOptions.map(({ label, value, translationSubKey }) => {
    const translatedLabel = t(`chat.meeting-selector.order.${translationSubKey}`, { defaultValue: label });
    return { label: translatedLabel, value };
  }), [t]);

  const [isLoading, setIsLoading] = useState(true);
  const [screen, setScreen] = useState<Screen>(mode === 'create' ? 'select' : 'review');
  const [filters, setFilters] = useState<State>({
    q: [],
    participants: [],
    daysAgo: undefined,
    order: translatedOrderOptions[0],
  });

  const [queriedMeetings, setQueriedMeetings] = useState<Meeting[]>([]);
  const [selectedMeetingIds, setSelectedMeetingIds] = useState<Set<number>>(new Set(chatConnectedMeetings));
  const [participantOptions, setParticipantOptions] = useState<Participant[]>([]);

  useEffect(() => {
    if(chatConnectedMeetings.length > 0) {
      setScreen('review');
    } else {
      setScreen('select');
    }
    setSelectedMeetingIds(new Set(chatConnectedMeetings));
  }, [open, chatConnectedMeetings])

  const groupedMeetings = useMemo<MeetingGroup[]>(() => {
    if (queriedMeetings.length === 0) {
      return [];
    }

    if (filters.order?.value === '-rank') {
      return [{ name: 'All', meetings: queriedMeetings, order: 0 }];
    }

    return queriedMeetings.reduce<MeetingGroup[]>((groups, meeting) => {
      const date = new Date(meeting.meeting_start_date_time * 1000);
      const order = date.getFullYear() * (date.getMonth() + 1);
      const group = groups.find((group) => group.order === order);
      if (!group) {
        return [...groups, {
          name: date.toLocaleString(i18n.language ?? 'default', { month: 'long', year: 'numeric' }),
          order,
          meetings: [meeting],
        }];
      }

      group.meetings.push(meeting);

      return groups;
    }, []);
  }, [queriedMeetings, filters.order]);

  useEffect(() => {
    (async () => {
      const { participants } = await apiGet<Participant[], 'participants'>({ path: '/participants' });
      setParticipantOptions(participants);
    })();
  }, []);

  const loadMeetings = useCallback(async (query: string) => {
    setIsLoading(true);
    try {
      const { transcripts } = await apiGet<Meeting[], 'transcripts'>({
        path: `/transcripts?${query}`,
      });
      setQueriedMeetings(transcripts);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if(!open) return;

    if (screen == 'review') {
      loadMeetings(Array.from(selectedMeetingIds).map((id) => `id=${id}`).join('&'));
    } else {
      const query = [`limit=${LIMIT}`];

      filters.q.forEach((s) => query.push(`q=${s.value}`));
      filters.participants.forEach((p) => query.push(`participants=${p.value}`));

      if (filters.daysAgo) {
        query.push(`startTime=${(Date.now() / 1000) - filters.daysAgo.value}`);
      }

      if (filters.order && filters.order.value !== '-rank') {
        query.push(`order=${filters.order.value}`);
      }

      loadMeetings(query.join('&'));
    }
  }, [filters, screen, open]);

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await onConfirm(Array.from(selectedMeetingIds));
    } finally {
      setIsLoading(false);
    }
  };

  const handleMeetingSelected = (id: number, selected: boolean) => {
    const newSet = new Set(selectedMeetingIds);

    if (selected) {
      newSet.add(id);
    } else {
      newSet.delete(id);
    }

    return setSelectedMeetingIds(newSet);
  };

  const handleGroupSelected: ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = Number(e.target.value);
    const groups = value === -1 ? groupedMeetings : groupedMeetings.filter(({ order }) => order === value);
    const newSet = new Set(selectedMeetingIds);

    if (groups.length === 0) throw new Error(`Can't find selected group ${value}`);

    if (e.target.checked) {
      groups.forEach((group) => (
        group.meetings.forEach(({ id }) => newSet.add(id))
      ));
    } else {
      groups.forEach((group) => (
        group.meetings.forEach(({ id }) => newSet.delete(id))
      ));
    }

    return setSelectedMeetingIds(newSet);
  };

  const handleOpenChange: (open: boolean) => void = (open) => {
    if (!open && isLoading) {
      return;
    }
    onOpenChange(open);
  }

  const title = screen === 'select' ? t('meeting-filter.title', 'Select the meetings available for your chat')
    : t('meeting-filter.confirm-title', 'Meetings available for your chat');


  return (
    <Dialog
      trigger={<FloatingTriggerButton forwardedAs="span" inline small>
        <span className='show-on-hover'>{t('show-chat.transcripts', 'Transcripts')}:</span>
        <b>{transcriptCount}</b>
      </FloatingTriggerButton>}
      open={open}
      onOpenChange={handleOpenChange}
      maxWidth="800px"
      title={title}
      titleStyle={{ fontSize: '18px', marginTop: 0 }}
      hideClose
    >
    <Container>
      {!isLoading && 
        <CloseButton onClick={onClose}><X /></CloseButton> 
      }
      {screen == 'select' && (
        <>
          <form onSubmit={handleSubmit} id="search">
            <SearchRow>
              <CreatableSelect
                isMulti
                defaultValue={filters.q}
                onChange={(options) => setFilters({ ...filters, q: options })} value={filters.q}
                classNamePrefix={"search"}
                placeholder={t('chat.meeting-selector.search.placeholder', 'Search')}
                components={{
                  Control: ({ children, ...props }: ControlProps<Option, true>) => {
                    return (
                      <components.Control {...props}>
                        <SearchIcon size={14} />
                        {children}
                      </components.Control>
                    );
                  }
                }}
              />
              <Select
                isMulti
                defaultValue={filters.participants}
                options={participantOptions.map(({ name }) => ({ label: name, value: name })) as Option[]}
                // @ts-expect-error todo
                onChange={(options) => setFilters({ ...filters, participants: options })}
                classNamePrefix={"rs"}
                placeholder={t('chat.meeting-selector.user', "Select user")}
                size='medium'
                components={{
                  // @ts-expect-error todo
                  Control: ({ children, ...props }: ControlProps<Option, true>) => {
                    return (
                      <components.Control {...props}>
                        <PersonCircle size={14} />
                        {children}
                      </components.Control>
                    );
                  }
                }}
              />
              <Select
                isClearable
                // @ts-expect-error todo
                value={filters.daysAgo}
                // @ts-expect-error todo
                options={translatedDateOptions}
                onChange={(option) => {
                  const singleOption = option as SingleValue<NumberOption>;
                  if (singleOption?.value === -1) {
                    return setFilters({ ...filters, daysAgo: null });
                  }
                  setFilters({ ...filters, daysAgo: singleOption });
                }}
                classNamePrefix={"rs"}
                placeholder={t('chat.meeting-selector.time.placeholder', 'All time')}
                size='medium'
                components={{
                  // @ts-expect-error todo
                  Control: ({ children, ...props }: ControlProps<NumberOption, false>) => {
                    return (
                      <components.Control {...props}>
                        <CalendarIcon size={14} />
                        {children}
                      </components.Control>
                    );
                  }
                }}
              />
              <Select
                isClearable={false}
                defaultValue={filters.order}
                options={translatedOrderOptions}
                // @ts-expect-error todo
                onChange={(option) => setFilters({ ...filters, order: option })}
                classNamePrefix={"rs"}
                components={{
                  // @ts-expect-error todo
                  Control: ({ children, ...props }: ControlProps<{ label: string, value: SortOrder; }, false>) => {
                    return (
                      <components.Control {...props}>
                        <SortArrow />
                        {children}
                      </components.Control>
                    );
                  }
                }}
              />
            </SearchRow>
          </form>
          <MeetingList>
            {!isLoading && groupedMeetings.length === 0 && <Centered>{t("chat.meeting-selector.no-meetings", "You don't have any meetings")}</Centered>}
            {isLoading ? <LoadingAnimation><ThreeDots color='var(--gray400)' /></LoadingAnimation> : (
              groupedMeetings.map((group) => (
                <React.Fragment key={group.name}>
                  {group.name !== 'All' && (
                    <GroupRow>
                      <input
                        type="checkbox"
                        value={group.order}
                        onChange={handleGroupSelected}
                        checked={group.meetings.every(({ id }) => selectedMeetingIds.has(id))}
                      /> {group.name}
                    </GroupRow>
                  )}
                  {group.meetings.map((meeting) => (
                    <MeetingRow
                      key={meeting.id}
                      meeting={meeting}
                      active={selectedMeetingIds.has(meeting.id)}
                      selected={selectedMeetingIds.has(meeting.id)}
                      onChange={handleMeetingSelected}
                    />
                  ))}
                </React.Fragment>
              ))
            )}
          </MeetingList>
          <Row>
            <Label>
              <input
                type="checkbox"
                onChange={handleGroupSelected}
                value={-1}
                checked={groupedMeetings.every(({ meetings }) => meetings.every(({ id }) => selectedMeetingIds.has(id)))}
              />
              <span>{t('chat.meeting-selector.select-all', 'Select All')}</span>
            </Label>

            <LinkButton small onClick={() => onClose()}>{t('close', { ns: 'Actions', defaultValue: 'Close' })}</LinkButton>
            <Button
              small
              type="button"
              onClick={() => setScreen('review')}
              disabled={selectedMeetingIds.size === 0}
            >{t('review', { ns: 'Actions', defaultValue: 'Review' })} ({selectedMeetingIds.size})</Button>
          </Row>
        </>
      )}
      {screen == 'review' && (
        <>
          {isLoading ? <LoadingAnimation><ThreeDots color='var(--gray400)' /></LoadingAnimation> : (
          <MeetingList>
            {selectedMeetingIds.size === 0 && <Centered>{t('chat.meeting-selector.no-meetings-selected', 'No meetings selected')}</Centered>}
            {queriedMeetings.map((meeting) => (
              <MeetingRow
                key={meeting.id}
                meeting={meeting}
                active={false}
                selected={selectedMeetingIds.has(meeting.id)}
                onChange={handleMeetingSelected}
              />
            ))}
          </MeetingList>
          )}
          <Row>
            <LinkButton small onClick={() => setScreen('select')}>{mode === 'create' ? t('back', { ns: 'Actions', defaultValue: 'Back' }) : t('add-more', { ns: 'Actions', defaultValue: 'Add More' })}</LinkButton>
            <form onSubmit={handleSubmit}>
              <Button
                small
              >{isLoading ? <ThreeDots color="white" width="20px" height="10px" /> : `${initialMode === 'create' ? t('add', { ns: 'Actions', defaultValue: 'Add' }) : t('confirm', { ns: 'Actions', defaultValue: 'Confirm' })} (${selectedMeetingIds.size})`}</Button>
            </form>
          </Row>
        </>
      )}
    </Container>
  </Dialog>
  );
}

