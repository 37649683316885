import { BACKEND_URL, I18N_SUPPORT } from '../config/config';
import React from 'react';
import * as Sentry from "@sentry/react";
import { getI18n } from 'react-i18next';
import { APIValidationError } from './api';

// Wrapper function around fetch
export async function csrfFetch(url, method, options = {}) {
  // Get the CSRF token
  // const csrfToken = await getCSRFToken();

  // Add the CSRF token as a header
  options.headers = {
    ...options.headers,
    // 'X-CSRF-Token': csrfToken
  };
  options.method = method;
  options.mode = 'cors';

  // Perform the fetch with the modified options
  const response = await fetch(url, options);

  // You can handle the response as needed
  return response;
}

export async function csrfGet(url, options = {}) {
  return csrfFetch(url, 'GET', options);
}

export async function csrfPut(url, options = {}) {
  return csrfFetch(url, 'PUT', options);
}

export async function csrfPost(url, options = {}) {
  return csrfFetch(url, 'POST', options);
}

export async function csrfDelete(url, options = {}) {
  return csrfFetch(url, 'DELETE', options);
}

export async function csrfPatch(url, options = {}) {
  return csrfFetch(url, 'PATCH', options);
}

export function getUserAccessTokenPromise() {
  return new Promise((resolve, reject) => {
    getUserAccessToken((result) => {
      if (result.error) return reject();

      resolve(result);
    });
  });
}

export async function apiGet({ path }) {
  const { access: accessToken } = await getUserAccessTokenPromise();

  const response = await csrfGet(`${BACKEND_URL}/api${path}`, {
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
    referrerPolicy: "no-referrer",
  });

  if (!response.ok) {
    throw new Error("API GET Failed");
  }

  return response.json();
}

export async function apiPost({ path, body, method = 'POST' }) {
  const { access: accessToken } = await getUserAccessTokenPromise();

  const response = await csrfFetch(`${BACKEND_URL}/api${path}`, method, {
    cache: "no-cache",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
    referrerPolicy: "no-referrer",
  });

  if (response.status === 422) {
    const json = await response.json();
    throw new APIValidationError(json.errors)
  }

  if (!response.ok) {
    throw new Error("API POST Failed");
  }

  return response.json();
}

export function apiPatch({ path, body }) {
  return apiPost({ path, body, method: 'PATCH' });
}

export function groupBy(array, criteria) {
  return array.reduce(function (obj, item) {
    // Get the value to group by based on the criteria function
    var key = criteria(item);

    // If the key doesn't exist yet, create an empty array for it
    if (!obj.hasOwnProperty(key)) {
      obj[key] = [];
    }

    // Push the item into the array for its respective key
    obj[key].push(item);

    return obj;
  }, {});
}

export function mapObject(obj, mapFn) {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key, mapFn(value)])
  );
}

export function formatDatetime(date) {
  const locale = getI18n().language;
  const dateObject = new Date(date);

  const dateString = dateObject.toLocaleDateString(locale, {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });

  const timeString = dateObject.toLocaleTimeString(locale, {
    hour: 'numeric',
    minute: 'numeric',
    hour12: false
  });

  return `${dateString} | ${timeString}`;
}

export function formatDate(dateObject) {
  const locale = getI18n().language;
  const options = {
    weekday: 'short',
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  };

  const formattedDate = dateObject.toLocaleDateString(locale, options);
  const upcasedDate = [formattedDate.charAt(0).toUpperCase(), formattedDate.slice(1)];
  return upcasedDate;
}

export function formatDateDDMMYYYY(inputDate) {
  const date = new Date(inputDate);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

export function groupConsecutiveByDeviceId(inputArray) {
  let outputArray = [];

  let currentBlock = null;
  for (let item of inputArray) {
    if (currentBlock === null || currentBlock.deviceId !== item.deviceId) {
      // start a new block
      currentBlock = { deviceId: item.deviceId, blocks: [item] };
      outputArray.push(currentBlock);
    } else {
      // add item to the current block
      currentBlock.blocks.push(item);
    }
  }

  return outputArray;
}

export function copyToClipboard(text) {
  var input = document.createElement('textarea');
  input.innerHTML = text;
  document.body.appendChild(input);
  input.select();
  var result = document.execCommand('copy');
  document.body.removeChild(input);

  return result;
}

export function logOut(forceReload = false) {
  Sentry.setUser(null);

  // window.dispatchEvent(new CustomEvent('background-message', { detail: { signOut: true}}));
  var accessTokenKey = "user-access-token";
  var accessTimestampKey = "user-access-token-timestamp";
  var refreshTokenKey = "user-refresh-token";

  localStorage.removeItem(accessTokenKey);
  localStorage.removeItem(accessTimestampKey);
  localStorage.removeItem(refreshTokenKey);

  const user_data_key = 'current-user-data';

  const userData = localStorage.getItem(user_data_key);

  localStorage.removeItem(user_data_key);

  if (forceReload || userData) {
    window.location.reload();
  }
}

export function isLoggedIn() {
  var accessTokenKey = "user-access-token";
  const accessToken = localStorage.getItem(accessTokenKey);

  return !!accessToken;
}

export function getUserAccessToken(callback) {
  var accessTokenKey = "user-access-token";
  var accessTimestampKey = "user-access-token-timestamp";

  const accessToken = localStorage.getItem(accessTokenKey);
  const accessTimestamp = localStorage.getItem(accessTimestampKey);

  var timestampNow = Date.now();

  var ACCESS_TOKEN_LIFETIME = 1000 * 60 * 60 * 3; // 3 hours

  if (accessToken && accessTimestamp && timestampNow - accessTimestamp < ACCESS_TOKEN_LIFETIME) {
    return callback({ access: accessToken });
  }

  var refreshTokenKey = "user-refresh-token";
  const refreshToken = localStorage.getItem(refreshTokenKey);

  if (accessToken && accessTimestamp && timestampNow - accessTimestamp < ACCESS_TOKEN_LIFETIME) {
    return callback({ access: accessToken, refresh: refreshToken })
  }

  if (!refreshToken) {
    callback({ error: true });
  }

  csrfPost(BACKEND_URL + '/api/token/refresh/', {
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json'
    },
    referrerPolicy: 'no-referrer',
    body: JSON.stringify({ refresh: refreshToken })
  })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        logOut();
        throw new Error("Failed to refresh token");
      }
    })
    .then(response => {

      localStorage.setItem(accessTokenKey, response.access);
      localStorage.setItem(accessTimestampKey, Date.now());

      callback({ access: response.access, refresh: refreshToken })
    })
    .catch(e => {
      callback({ error: true });
    });
}

export function getUserInfo() {
  try {
    const user_data_key = 'current-user-data';
    const res = JSON.parse(localStorage.getItem(user_data_key)) || { error: true };
    Sentry.setUser(res?.profile?.email ? { email: res?.profile?.email } : null);
    return res;
  } catch {
    return { error: true };
  }
}

export function getRenewedUserInfo() {
  getUserAccessToken(({ access, error }) => {
    if (error) {
      Sentry.setUser(null);
      return;
    }

    csrfGet(BACKEND_URL + '/api/get_user', {
      cache: 'no-cache',
      headers: {
        'Authorization': 'Bearer ' + access
      },
      referrerPolicy: 'no-referrer'
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          logOut();
          Sentry.setUser(null);
          throw new Error("Failed to get user");
        }
      })
      .then(user_response => {
        const user_data_key = 'current-user-data';
        localStorage.setItem(user_data_key, JSON.stringify(user_response));
        if (user_response?.profile?.email) {
          Sentry.setUser({ email: user_response?.profile?.email });
        } else {
          Sentry.setUser(null);
        }

      });
  });
}

export function getTranscriptionAsText(meetingData) {
  var r = '';
  var currentSpeaker = '';

  for (const block of meetingData?.transcriptionBlocks ?? []) {
    if (currentSpeaker !== block.deviceId) {
      currentSpeaker = block.deviceId;
      const deviceName = meetingData.deviceNamesById[currentSpeaker];

      if(deviceName !== undefined && deviceName !== "undefined") {
        r = r + '\n' + deviceName + ':';
      }
    }
    r += ' ' + block.text;
  }

  return r.trim();
}

export function generatePromptOutput(meetingData, promptBeingProcessed, accessToken, version, isGpt4 = false) {
  return csrfPost(BACKEND_URL + '/api/briefly/v2/submit-briefly-request', {
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + accessToken
    },
    referrerPolicy: 'no-referrer',
    body: JSON.stringify({
      endpoint: 'create-custom-summary',
      transcript: getTranscriptionAsText(meetingData),
      prompt: promptBeingProcessed.prompt,
      type: 'create-custom-summary', // required to avoid backend hash bug
      version: version,
      model: isGpt4 ? 'gpt-4o-mini' : 'gpt-3.5-turbo'
    })
  }).then(response => {
    if (response.ok) {
      return response.json();
    }
    throw new Error('Failed to submit briefly request');
  })
    .then(response => {
      const { request_id } = response;
      return new Promise((resolve, reject) => {
        var interval;
        var timeout;

        interval = setInterval(() => {

          csrfGet(BACKEND_URL + '/api/briefly/v2/check-briefly-request/' + request_id, {
            cache: 'no-cache',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + accessToken
            },
            referrerPolicy: 'no-referrer'
          }).then(response => {
            if (response.ok) {
              trackEvent("briefly-create-output-success");
              clearInterval(interval);
              clearTimeout(timeout);
              resolve(response);
            }
          });
        }, 2000);

        timeout = setTimeout(() => {
          trackEvent("briefly-create-output-timeout");
          clearInterval(interval);
          reject("timed out after 90 seconds");
        }, 180 * 1000);
      });
    })
    .then(response => response.json())
    .catch(response => {
      return Promise.resolve({ error: true });
    });
}

async function generatePartOfSummaryAbstractRequest(transcript, transcriptId, selected_language, token, amplitudeEventSuccess, amplitudeEventTimeout, endpoint, errorObj, extraRequestData = {}) {
  return csrfPost(BACKEND_URL + '/api/briefly/v2/submit-briefly-request', {
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    referrerPolicy: 'no-referrer',
    body: JSON.stringify({
      endpoint: endpoint,
      transcript,
      transcriptId,
      type: endpoint, // required to avoid backend hash bug
      selected_language,
      ...extraRequestData
    })
  })
    .then(response => {
      // if(false) {
      if (response.ok) {
        return response.json();
      } else {
        return Promise.resolve(errorObj);
      }
    }).then(response => {
      const { request_id } = response;
      return new Promise((resolve, reject) => {
        var interval;
        var timeout;

        interval = setInterval(() => {
          csrfGet(BACKEND_URL + '/api/briefly/v2/check-briefly-request/' + request_id, {
            cache: 'no-cache',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token
            },
            referrerPolicy: 'no-referrer'
          }).then(response => {
            if (response.ok) {
              trackEvent(amplitudeEventSuccess);
              clearInterval(interval);
              clearTimeout(timeout);
              resolve(response);
            }
          });
        }, 2000);

        timeout = setTimeout(() => {
          trackEvent(amplitudeEventTimeout);
          clearInterval(interval);
          reject("timed out after 90 seconds");
          // throw "timed out after 90 seconds"
        }, 90 * 1000);
      });
    })
    .then(response => response.json())
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(response => {
      return Promise.resolve(errorObj);
    });
}

async function generateSummaryRequest(transcript, transcriptId, selected_language, token) {
  return generatePartOfSummaryAbstractRequest(transcript, transcriptId, selected_language, token,
    "briefly-create-bullet-summary-success",
    "briefly-create-bullet-summary-timeout",
    "create-bullet-summary-v2",
    {
      short_summary: { error: true },
      long_summary: { error: true }
    }
  );
}

async function generateActionItemsRequest(transcript, transcriptId, selected_language, token) {
  return generatePartOfSummaryAbstractRequest(transcript, transcriptId, selected_language, token,
    "briefly-create-action-items-success",
    "briefly-create-action-items-timeout",
    "create-action-items",
    {
      action_items: { error: true }
    }
  );
}

async function generateKeyInsightsRequest(transcript, transcriptId, selected_language, token) {
  return generatePartOfSummaryAbstractRequest(transcript, transcriptId, selected_language, token,
    "briefly-create-key-insights-success",
    "briefly-create-key-insights-timeout",
    "create-key-insights",
    {
      key_insights: { error: true }
    }
  );
}

async function generateFollowupEmailRequest(transcript, transcriptId, token) {
  return generatePartOfSummaryAbstractRequest(transcript, transcriptId, token,
    "briefly-create-followup-email-success",
    "briefly-create-followup-email-timeout",
    "generate-followup-email",
    {
      email_body: { error: true }
    }
  ).then(response => {
    return Promise.resolve({
      ...response,
      email_prompt: ''
    });
  });
}

export async function generateSummary(transcript, transcriptId, selected_language, token, callback) {
  const r = transcript;

  var responses = await Promise.all([
    generateSummaryRequest(r, transcriptId, selected_language, token).then(json => {
      return Promise.resolve(json);
    }),
    generateActionItemsRequest(r, transcriptId, selected_language, token).then(json => {
      return Promise.resolve(json);
    }),
    generateKeyInsightsRequest(r, transcriptId, selected_language, token).then(json => {
      return Promise.resolve(json);
    })
  ]);

  var mergedResponse = {};

  for (var response of responses) {
    var json = response;
    for (var k in json) {
      mergedResponse[k] = json[k];
    }
  }

  var response = mergedResponse;

  callback(response);
}

export function uploadTranscriptRequest(transcript, meetingName, meetingStartDate, accessToken) {
  return csrfPost(BACKEND_URL + '/api/briefly/transcript', {
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + accessToken
    },
    referrerPolicy: 'no-referrer',
    body: JSON.stringify({
      transcript,
      meetingName,
      meetingStartDate,
      meetingPlatform: 'Zoom',
      sendEmail: false
    })
  }).then(response => {
    if (response.ok) {
      return response.json();
    } else {
      return Promise.resolve({ error: true });
    }
  });
}

export function sendZoomCodeToBackend(code, accessToken) {
  return csrfPost(BACKEND_URL + '/api/briefly/oauth-zoom', {
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + accessToken
    },
    referrerPolicy: 'no-referrer',
    body: JSON.stringify({ code })  // The Zoom authorization code
  }).then(response => {
    if (response.ok) {
      return response.json();
    } else {
      return Promise.resolve({ error: true });
    }
  });
}

export function removeZoomAuth(access) {
  return csrfPatch(BACKEND_URL + '/api/update_user_settings', {
    cache: 'no-cache',
    headers: {
      'Authorization': 'Bearer ' + access,
      'Content-Type': 'application/json'
    },
    referrerPolicy: 'no-referrer',
    body: JSON.stringify({
      is_integration_active_zoom: false
    })
  }).then(response => response.json()).then(user_response => {
    const user_data_key = 'current-user-data';
    localStorage.setItem(user_data_key, JSON.stringify(user_response));
  });
}


export function getUploadedTranscriptsRequest(query = null) {
  return new Promise((resolve, reject) => {
    getUserAccessToken(({ access, error }) => {
      if (error) {
        return resolve({ error: true });
      }
      csrfGet(BACKEND_URL + '/api/briefly/user-transcripts?including_google_meet=1' + (query ? `&query=${query}` : ''), {
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + access
        },
        referrerPolicy: 'no-referrer'
      }).then(response => {
        if (response.ok) {
          return response.json();
        } else {
          return Promise.resolve({ error: true });
        }
      }).then(response => {
        resolve(response);
      });
    });
  });
}

export function refreshUploadedTranscriptsRequest({ refresh_transcript_ids, get_transcript_ids_after }) {
  return new Promise((resolve, reject) => {
    getUserAccessToken(({ access }) => {
      csrfGet(BACKEND_URL + `/api/briefly/refresh-user-transcripts?refresh_transcript_ids=${refresh_transcript_ids.join(',')}&get_transcript_ids_after=${get_transcript_ids_after}`, {
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + access
        },
        referrerPolicy: 'no-referrer'
      }).then(response => {
        if (response.ok) {
          return response.json();
        } else {
          return Promise.resolve({ error: true });
        }
      }).then(response => {
        resolve(response);
      });
    });
  });
}

export function getUploadedTranscriptFullData(transcriptId) {
  return new Promise((resolve, reject) => {
    getUserAccessToken(({ access }) => {
      csrfGet(BACKEND_URL + '/api/briefly/transcript?transcriptId=' + transcriptId, {
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + access
        },
        referrerPolicy: 'no-referrer'
      }).then(response => {
        if (response.ok) {
          return response.json();
        } else {
          return Promise.resolve({ error: true });
        }
      }).then(response => {
        resolve(response);
      });
    });
  });
}

export function trackEvent(eventType, eventProperties) {
  getUserAccessToken(({ access }) => {
    csrfPost(BACKEND_URL + '/api/briefly/track-event', {
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + access
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        eventType,
        eventProperties,
        datetime: new Date().toISOString()
      })
    });
  });
}

export function removeMeetingById(meetingId) {
  window.dispatchEvent(new CustomEvent('background-message', { detail: { removeMeetingById: meetingId } }));
}

export function removeBackendMeetingById(meetingId) {
  return new Promise((resolve, reject) => {
    getUserAccessToken(({ access }) => {
      csrfDelete(BACKEND_URL + '/api/briefly/transcript', {
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + access
        },
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({
          transcriptId: meetingId.split('-')[1]
        })
      }).then(response => {
        if (response.ok) {
          return response.json();
        } else {
          return Promise.resolve({ error: true });
        }
      }).then(response => {
        resolve(response);
      });
    });
  });
}

export function updateTranscriptSettings(meetingId, settings) {
  return new Promise((resolve, reject) => {
    getUserAccessToken(({ access }) => {
      csrfPatch(BACKEND_URL + '/api/briefly/transcript', {
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + access
        },
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({
          transcriptId: meetingId.split('-')[1],
          ...settings
        })
      }).then(response => {
        if (response.ok) {
          return response.json();
        } else {
          return Promise.resolve({ error: true });
        }
      }).then(response => {
        resolve(response);
      });
    });
  });
}

export function removeBullets(text, bullet_format = "• ") {
  if (!text.split) {
    return '';
  }

  return text.split("\n").map(function (line) {
    if (line.startsWith(bullet_format)) {
      return `${line.replace(bullet_format, "").trim()}`;
    } else {
      return line;
    }
  });
}

export const splitStringIntoArrays = (str, delimiters) => {
  let result = [[]];
  let currentArray = 0;

  for (let char of str) {
    if (delimiters.includes(char)) {
      result.push([]);
      currentArray++;
    } else {
      result[currentArray].push(char);
    }
  }

  return result;
};

export function groupStringIntoArray(text) {
  if (!text.split) {
    return '';
  }
  const lines = text.split("\n");
  const arrays = splitStringIntoArrays(lines, [""]);

  return arrays;
}

export function addColourToNames(text, getColourByName) {
  var COLORS = ["#49851A", "#0079D1", "#CD4B03", "#D13388", "#BD7D00", "#008464", "#6967D7", "#468079"];
  var COLORS_i = 0;

  if (!text.split) {
    return '';
  }

  let lines = text.split("\n");

  for (let i = 0; i < lines.length; i++) {
    if (lines[i].endsWith(':')) {
      lines[i] = `${i === 0 ? '' : '<br>'}<strong style="font-size:14px; line-height:22px; color:${getColourByName ? getColourByName(lines[i].split(':')[0]) : COLORS[COLORS_i++]};">${lines[i]}</strong>`;
    }
  }

  return lines.join("\n");
}

export function getContextStrings(big_string, words, context_size) {
  let context_strings = [];

  words = words.map(x => x.toLowerCase());

  const allWordsSet = new Set(words);
  const wordsThatMatchedSet = new Set();

  if (!big_string || big_string.error) {
    return [];
  }

  // Split the big string into individual words
  let big_words = big_string.split(/\s+/);;

  // Iterate over each word in the big string
  for (let i = 0; i < big_words.length; i++) {
    let word = big_words[i];

    const matchedWord = words.find(w => word.toLowerCase().startsWith(w));
    // Check if the current word matches any of the words in the list
    if (matchedWord) {
      // Calculate the starting and ending indices for the surrounding context
      let start_index = Math.max(0, i - context_size);
      let end_index = Math.min(big_words.length, i + context_size + 1);

      // Extract the surrounding context words
      let context_words = big_words.slice(start_index, end_index);

      // Join the context words into a string
      let context_string = context_words.join(" ");

      // Add the context string to the result list
      context_strings.push(context_string);

      wordsThatMatchedSet.add(matchedWord);
    }
  }

  if (wordsThatMatchedSet.length !== allWordsSet.length) {
    return [];
  }

  return context_strings;
}

export function getContextStringsFromList(big_string_list, words, context_size) {
  var res = [];
  for (var big_string of big_string_list) {
    res = res.concat(getContextStrings(big_string, words, context_size));
  }
  return res;
}

export function plural(wordSingle, wordPlural, count) {
  return count > 1 ? wordPlural : wordSingle;
}

export function mergeObjectsByMeetingId(objects) {
  const mergedObjects = {};

  for (let obj of objects) {
    const meetingId = obj.meetingId;

    if (!mergedObjects[meetingId]) {
      mergedObjects[meetingId] = { ...obj };
    } else {
      mergedObjects[meetingId] = { ...mergedObjects[meetingId], ...obj };
    }
  }

  return Object.values(mergedObjects);
}

export function highlightText(text, wordsToHighlight, highlightStyle) {
  if (!text.split) {
    return text;
  }
  const words = text.split(/\s+/);

  wordsToHighlight = wordsToHighlight.filter(x => x.trim());

  const highlightedText = words.map((word, index) => {
    const lowercaseWord = word.toLowerCase();
    const isHighlighted = wordsToHighlight.some(
      highlightWord => lowercaseWord.startsWith(highlightWord.toLowerCase())
    );

    return (
      <span key={index} style={{
        ...(isHighlighted ? highlightStyle : {})
      }} className={`search-word ${isHighlighted ? "highlight" : ''}`}>
        {word}
      </span>
    );
  });

  return <>{highlightedText}</>;
}

export function countWordSetMatches(transcript, words) {
  // Convert the transcript to lowercase and split it into words
  const transcriptWords = transcript.toLowerCase().split(' ');
  const lowercaseWords = words.map(word => word.toLowerCase());
  const matchCounts = [];

  for (const word of lowercaseWords) {
    if (!word.trim().length) {
      continue;
    }
    const matchCount = transcriptWords.filter(transcriptWord => transcriptWord.startsWith(word)).length;
    matchCounts.push(matchCount);
  }

  return Math.min(...matchCounts);
}

export function setEmailNotificationsEnabled(value) {
  getUserAccessToken(({ access }) => {
    csrfPatch(BACKEND_URL + '/api/update_user_settings', {
      cache: 'no-cache',
      headers: {
        'Authorization': 'Bearer ' + access,
        'Content-Type': 'application/json'
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        subscribe_to_emails: value
      })
    }).then(response => response.json()).then(user_response => {
      const user_data_key = 'current-user-data';
      localStorage.setItem(user_data_key, JSON.stringify(user_response));
    });
  });
}

export function setAttendeesEmailNotificationsEnabled(value) {
  getUserAccessToken(({ access }) => {
    csrfPatch(BACKEND_URL + '/api/update_user_settings', {
      cache: 'no-cache',
      headers: {
        'Authorization': 'Bearer ' + access,
        'Content-Type': 'application/json'
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        is_subscribed_to_attendees_emails: value
      })
    }).then(response => response.json()).then(user_response => {
      const user_data_key = 'current-user-data';
      localStorage.setItem(user_data_key, JSON.stringify(user_response));
    });
  });
}

export function disconnectGoogleCalendar() {
  getUserAccessToken(({ access }) => {
    csrfPatch(BACKEND_URL + '/api/update_user_settings', {
      cache: 'no-cache',
      headers: {
        'Authorization': 'Bearer ' + access,
        'Content-Type': 'application/json'
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        is_integration_active_google_calendar: false
      })
    }).then(response => response.json()).then(user_response => {
      const user_data_key = 'current-user-data';
      localStorage.setItem(user_data_key, JSON.stringify(user_response));
    });
  });
}

export function setHubspotIntegrationIsActive(is_integration_active_hubspot) {
  getUserAccessToken(({ access }) => {
    csrfPatch(BACKEND_URL + '/api/update_user_settings', {
      cache: 'no-cache',
      headers: {
        'Authorization': 'Bearer ' + access,
        'Content-Type': 'application/json'
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        is_integration_active_hubspot: is_integration_active_hubspot
      })
    }).then(response => response.json()).then(user_response => {
      const user_data_key = 'current-user-data';
      localStorage.setItem(user_data_key, JSON.stringify(user_response));
    });
  });
}

export function setSalesforceIntegrationIsActive(is_integration_active_salesforce) {
  getUserAccessToken(({ access }) => {
    csrfPatch(BACKEND_URL + '/api/update_user_settings', {
      cache: 'no-cache',
      headers: {
        'Authorization': 'Bearer ' + access,
        'Content-Type': 'application/json'
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        is_integration_active_salesforce: is_integration_active_salesforce
      })
    }).then(response => response.json()).then(user_response => {
      const user_data_key = 'current-user-data';
      localStorage.setItem(user_data_key, JSON.stringify(user_response));
    });
  });
}

export function disconnectHubspot() {
  getUserAccessToken(({ access }) => {
    csrfPatch(BACKEND_URL + '/api/update_user_settings', {
      cache: 'no-cache',
      headers: {
        'Authorization': 'Bearer ' + access,
        'Content-Type': 'application/json'
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        is_integration_active_hubspot: false,
        force_disconnect_hubspot: true
      })
    }).then(response => response.json()).then(user_response => {
      const user_data_key = 'current-user-data';
      localStorage.setItem(user_data_key, JSON.stringify(user_response));
    });
  });
}

export function setHubspotGrowthOpportunitiesActive(is_growth_opportunities_active_hubspot) {
  getUserAccessToken(({ access }) => {
    csrfPatch(BACKEND_URL + '/api/update_user_settings', {
      cache: 'no-cache',
      headers: {
        'Authorization': 'Bearer ' + access,
        'Content-Type': 'application/json'
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        is_growth_opportunities_active_hubspot: is_growth_opportunities_active_hubspot
      })
    }).then(response => response.json()).then(user_response => {
      const user_data_key = 'current-user-data';
      localStorage.setItem(user_data_key, JSON.stringify(user_response));
    });
  });
}

export function setSalesforceGrowthOpportunitiesActive(is_growth_opportunities_active_salesforce) {
  getUserAccessToken(({ access }) => {
    csrfPatch(BACKEND_URL + '/api/update_user_settings', {
      cache: 'no-cache',
      headers: {
        'Authorization': 'Bearer ' + access,
        'Content-Type': 'application/json'
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        is_growth_opportunities_active_salesforce: is_growth_opportunities_active_salesforce
      })
    }).then(response => response.json()).then(user_response => {
      const user_data_key = 'current-user-data';
      localStorage.setItem(user_data_key, JSON.stringify(user_response));
    });
  });
}

export function disconnectSalesforce() {
  getUserAccessToken(({ access }) => {
    csrfPatch(BACKEND_URL + '/api/update_user_settings', {
      cache: 'no-cache',
      headers: {
        'Authorization': 'Bearer ' + access,
        'Content-Type': 'application/json'
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        is_integration_active_salesforce: false,
        force_disconnect_salesforce: true
      })
    }).then(response => response.json()).then(user_response => {
      const user_data_key = 'current-user-data';
      localStorage.setItem(user_data_key, JSON.stringify(user_response));
    });
  });
}

export function hubspotCallback(code, callback) {
  getUserAccessToken(({ access }) => {
    csrfPost(BACKEND_URL + '/api/briefly/hubspot-auth-callback?code=' + code, {
      cache: 'no-cache',
      headers: {
        'Authorization': 'Bearer ' + access,
        'Content-Type': 'application/json'
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        is_integration_active_hubspot: false
      })
    }).then(response => response.json()).then(user_response => {
      callback();
    });
  });
}

export function updateUserSettings(settingsToUpdate, callback) {
  getUserAccessToken(({ access }) => {
    csrfPatch(BACKEND_URL + '/api/update_user_settings', {
      cache: 'no-cache',
      headers: {
        'Authorization': 'Bearer ' + access,
        'Content-Type': 'application/json'
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(settingsToUpdate)
    }).then(response => response.json()).then(user_response => {
      const user_data_key = 'current-user-data';
      localStorage.setItem(user_data_key, JSON.stringify(user_response));
      if (callback) {
        callback();
      }
    });
  });
}

export function authCalendar(code, callback) {
  getUserAccessToken(({ access }) => {
    csrfPost(BACKEND_URL + '/api/briefly/auth-google-calendar', {
      cache: 'no-cache',
      headers: {
        'Authorization': 'Bearer ' + access,
        'Content-Type': 'application/json'
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        token: code
      })
    })
      .then(calendar_response => calendar_response.json())
      .then(calendar_response => {
        callback(calendar_response);
      });
  });
}

export function disconnectGoogleDrive() {
  getUserAccessToken(({ access }) => {
    csrfPatch(BACKEND_URL + '/api/update_user_settings', {
      cache: 'no-cache',
      headers: {
        'Authorization': 'Bearer ' + access,
        'Content-Type': 'application/json'
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        is_integration_active_google_drive: false
      })
    }).then(response => response.json()).then(user_response => {
      const user_data_key = 'current-user-data';
      localStorage.setItem(user_data_key, JSON.stringify(user_response));
    });
  });
}

export function authDrive(code, callback) {
  getUserAccessToken(({ access }) => {
    csrfPost(BACKEND_URL + '/api/briefly/auth-google-drive', {
      cache: 'no-cache',
      headers: {
        'Authorization': 'Bearer ' + access,
        'Content-Type': 'application/json'
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        token: code
      })
    })
      .then(calendar_response => calendar_response.json())
      .then(calendar_response => {
        callback(calendar_response);
      });
  });
}

export function attendeesWithColor(attendees) {
  const COLOR_POOL = [
    '#F0631B',
    '#BE00B6',
    '#E44867',
    '#097449',
    '#00A3FF',
    '#80BF28',
    '#00A4D8',
    '#EC8E00',
    '#F46FB7',
    '#03C289',
    '#B4B3FE',
    '#FF5B6F',
  ];

  return attendees.map((attendee, i) => {
    return { name: attendee, deviceColor: COLOR_POOL[i % COLOR_POOL.length] };
  });
}

export function backendMeetingDataToLocalMeetingData(transcript) {
  const { meetingDateTime, meetingName, transcriptId, meeting_status, meeting_participants, meetingPlatform, recording_started_zoom, meeting_ended_zoom } = transcript;

  const attendeesByColor = attendeesWithColor(meeting_participants);

  return {
    attendeesByColor,
    isBackend: true,
    meetingName: meetingName || 'No Name',
    transcriptId,
    meetingId: 'bkend-' + transcriptId,
    meetingStartDate: meetingDateTime,
    meeting_status,
    meeting_participants,
    meetingPlatform,
    recording_started_zoom,
    meeting_ended_zoom
  };
}

export function getGoogleDriveLink(transcriptId, callback) {
  getUserAccessToken(({ access }) => {
    return csrfPost(BACKEND_URL + '/api/briefly/create-transcript-google-doc', {
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + access
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({ transcriptId })  // The Zoom authorization code
    }).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        return Promise.resolve({ error: true });
      }
    }).then(response => {
      callback(response);
    });
  });
}

export function resendKeyInsightsSlack(meetingData) {
  const meetingIdChromeExtension = meetingData.meetingId;
  const transcriptId = meetingData.transcriptId;

  getUserAccessToken(({ access }) => {
    return csrfPost(BACKEND_URL + '/api/briefly/v2/submit-briefly-request', {
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + access
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        endpoint: "resend-slack-summary",
        meetingIdChromeExtension,
        transcriptId
      })  // The Zoom authorization code
    }).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        return Promise.resolve({ error: true });
      }
    }).then(response => {
    });
  });
}

export function resendSummaryEmail(meetingData, { sendToEveryone }) {
  getUserAccessToken(({ access }) => {
    return csrfPost(BACKEND_URL + '/api/briefly/v2/submit-briefly-request', {
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + access
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        endpoint: "meeting-ended-callback",
        transcriptId: meetingData.transcriptId,
        forceSendInsightsEmail: true,
        forceMyself: !sendToEveryone,
        forceEveryone: sendToEveryone,
        meetingDateTime: meetingData.meetingStartDate,
        transcript: /*meetingData.isBackend? null :*/ getTranscriptionAsText(meetingData),
        meetingName: meetingData.meetingName,
        meetingParticipants: meetingData.isBackend ? meetingData.meeting_participants : Object.values(meetingData.deviceNamesById),
        sendEmail: true,
        callLanguage: meetingData.summary_selected_language,
        uiLanguage: I18N_SUPPORT ? getI18n().language : 'en',
      })  // The Zoom authorization code
    }).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        return Promise.resolve({ error: true });
      }
    }).then(response => {
    });
  });
}

export function sendTeamInvitation(emails, callback) {
  getUserAccessToken(({ access }) => {
    const promises = [];
    for (var email of emails) {
      promises.push(csrfPost(BACKEND_URL + '/api/briefly/send-team-invite', {
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + access
        },
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({
          inviteEmail: email
        })
      }));
    }

    Promise.all(promises).then(() => {
      callback();
    });
  });
}

export function openCheckoutSessionInNewTab() {
  getUserAccessToken(({ access }) => {
    csrfPost(BACKEND_URL + '/api/briefly/create_stripe_customer_checkout_session', {
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + access
      },
      referrerPolicy: 'no-referrer'
    })
      .then(response => response.json())
      .then(response => {
        window.open(response.url, '_blank').focus();
      });
  });
}

export function openCustomerPortalSessionInNewTab() {
  getUserAccessToken(({ access }) => {
    csrfPost(BACKEND_URL + '/api/briefly/create_stripe_customer_portal_session', {
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + access
      },
      referrerPolicy: 'no-referrer'
    })
      .then(response => response.json())
      .then(response => {
        window.open(response.url, '_blank').focus();
      });
  });
}

export function confirmTeamInvitation(adminEmail, callback) {
  getUserAccessToken(({ access }) => {
    csrfPost(BACKEND_URL + '/api/briefly/confirm-team-invitation', {
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + access
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        adminEmail: adminEmail
      })
    }).then(() => {
      callback();
    });
  });
}

export function rejectTeamInvitation(adminEmail, callback) {
  getUserAccessToken(({ access }) => {
    csrfPost(BACKEND_URL + '/api/briefly/reject-team-invitation', {
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + access
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        adminEmail: adminEmail
      })
    }).then(() => {
      callback();
    });
  });
}

export function removeMyselfFromTeam(adminEmail, callback) {
  getUserAccessToken(({ access }) => {
    csrfPost(BACKEND_URL + '/api/briefly/remove-myself-from-team', {
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + access
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        adminEmail: adminEmail
      })
    }).then(() => {
      callback();
    });
  });
}

export function removeTeamMember(removeEmail, callback) {
  getUserAccessToken(({ access }) => {
    csrfPost(BACKEND_URL + '/api/briefly/remove-team-member', {
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + access
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        removeEmail
      })
    }).then(() => {
      callback();
    });
  });
}

export function deleteRejectedInvite(removeEmail, callback) {
  getUserAccessToken(({ access }) => {
    csrfPost(BACKEND_URL + '/api/briefly/delete-rejected-invite', {
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + access
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        removeEmail
      })
    }).then(() => {
      callback();
    });
  });
}

export function checkIfStandardSummaryWasMadeBefore(transcript, meetingIdChromeExtension, callback) {
  getUserAccessToken(({ access }) => {
    csrfPost(BACKEND_URL + '/api/briefly/check-if-request-was-made-before', {
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + access
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        transcript,
        meetingIdChromeExtension,
        endpoint: 'create-bullet-summary-v2'
      })
    }).then(response => {
      return response.json();
    }).then(data => {
      callback(data);
    });
  });
}

export function checkIfStandardSummaryIsEligible(transcript, callback) {
  getUserAccessToken(({ access }) => {
    csrfPost(BACKEND_URL + '/api/briefly/check-if-request-is-eligible', {
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + access
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        transcript,
        endpoint: 'create-bullet-summary-v2'
      })
    }).then(response => {
      return response.json();
    }).then(data => {
      callback(data.result);
    });
  });
}

export function checkIfCustomSummaryIsEligible(transcript, prompt, callback) {
  getUserAccessToken(({ access }) => {
    csrfPost(BACKEND_URL + '/api/briefly/check-if-request-is-eligible', {
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + access
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        transcript,
        prompt,
        endpoint: 'create-custom-summary'
      })
    }).then(response => {
      return response.json();
    }).then(data => {
      callback(data.result);
    });
  });
}

export function sendUserFeedback(text, isBug, isOnboarding, callback) {
  getUserAccessToken(({ access }) => {
    csrfPost(BACKEND_URL + '/api/briefly/user-feedback-log', {
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + access
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        message: text,
        is_onboarding: isOnboarding,
        is_bug: isBug
      })
    }).then(response => {
      return response.json();
    }).then(data => {
      callback(data.result);
    });
  });
}

export function isBrieflySuccess() {
  return [
    'd22sp8pes253lh.cloudfront.net', 'dkdq99nmk8mla.cloudfront.net', 'success.brieflyai.com', 'success-staging.brieflyai.com'
  ].includes(window.location.host);
}

export function hubspotInitiateOauth() {
  getUserAccessToken(({ access }) => {
    csrfGet(BACKEND_URL + '/api/briefly/hubspot-initiate-oauth', {
      cache: 'no-cache',
      headers: {
        'Authorization': 'Bearer ' + access,
        'Content-Type': 'application/json'
      },
      referrerPolicy: 'no-referrer'
    }).then(response => response.json()).then(response => {
      window.location = response.url;
    });
  });
}

export function salesforceCallback(code, callback) {
  getUserAccessToken(({ access }) => {
    csrfPost(BACKEND_URL + '/api/briefly/salesforce-auth-callback?code=' + code, {
      cache: 'no-cache',
      headers: {
        'Authorization': 'Bearer ' + access,
        'Content-Type': 'application/json'
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({

      })
    }).then(response => response.json()).then(user_response => {
      callback();
    });
  });
}

export function recallAiBotAction(transcription_id, action, callback) {
  getUserAccessToken(({ access }) => {
    csrfPost(BACKEND_URL + '/api/briefly/recallai/bot_action', {
      cache: 'no-cache',
      headers: {
        'Authorization': 'Bearer ' + access,
        'Content-Type': 'application/json'
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        transcription_id,
        action
      })
    }).then(response => response.json()).then(user_response => {
      callback();
    });
  });
}

export function recallAiBotPause(transcription_id, callback) {
  recallAiBotAction(transcription_id, "pause", callback);
}

export function recallAiBotResume(transcription_id, callback) {
  recallAiBotAction(transcription_id, "resume", callback);
}

export function recallAiBotKickOut(transcription_id, callback) {
  recallAiBotAction(transcription_id, "kick_out", callback);
}

export function setupZoom(botShouldJoinAllCalls, callback) {
  getUserAccessToken(({ access }) => {
    csrfPost(BACKEND_URL + '/api/briefly/recallai/zoom_setup', {
      cache: 'no-cache',
      headers: {
        'Authorization': 'Bearer ' + access,
        'Content-Type': 'application/json'
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        botShouldJoinAllCalls
      })
    }).then(response => response.json()).then(user_response => {
      callback();
    });
  });
}

export function getNumberOfLines(element) {
  const ta = element;
  const styles = window.getComputedStyle
    ? window.getComputedStyle(ta)
    : ta.currentStyle;
  // This will get the line-height only if it is set in the css,
  // otherwise it's "normal"
  if (styles.lineHeight === "normal") {
    throw new Error(
      "Please set a fixed line-height css property to get the number of lines"
    );
  }
  const taLineHeight = parseInt(styles.lineHeight, 10);
  // Get the scroll height of the textarea
  const taHeight = calculateContentHeight(ta, taLineHeight);
  // calculate the number of lines
  const numberOfLines = Math.floor(taHeight / taLineHeight);


  // Code above results in 0 for 0-1 lines, and 3+ for 2+ lines
  if (numberOfLines === 0) {
    return 1;
  } else {
    return numberOfLines - 1;
  }
}

export function calculateContentHeight(ta, scanAmount) {
  const origHeight = ta.style.height;
  const scrollHeight = ta.scrollHeight;
  const overflow = ta.style.overflow;
  let height = ta.offsetHeight;
  /// only bother if the ta is bigger than content
  if (height >= scrollHeight) {
    /// check that our browser supports changing dimension
    /// calculations mid-way through a function call...
    ta.style.height = height + scanAmount + "px";
    /// because the scrollbar can cause calculation problems
    ta.style.overflow = "hidden";
    /// by checking that scrollHeight has updated
    if (scrollHeight < ta.scrollHeight) {
      /// now try and scan the ta's height downwards
      /// until scrollHeight becomes larger than height
      while (ta.offsetHeight >= ta.scrollHeight) {
        ta.style.height = (height -= scanAmount) + "px";
      }
      /// be more specific to get the exact height
      while (ta.offsetHeight < ta.scrollHeight) {
        ta.style.height = height++ + "px";
      }
      /// reset the ta back to it's original height
      ta.style.height = origHeight;
      /// put the overflow back
      ta.style.overflow = overflow;
      return height;
    }
  } else {
    return scrollHeight;
  }
}

export function checkIfRequestWasMadeBefore(transcript, meetingId, callback) {
  getUserAccessToken(({ access }) => {
    csrfPost(BACKEND_URL + '/api/briefly/check-if-request-was-made-before', {
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + access
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        transcript: transcript,
        endpoint: 'create-bullet-summary-v2',
        meetingIdChromeExtension: meetingId
      })
    }).then(response => response.json()).then(result_response => {
      callback(result_response);
    });
  });
}

export function getSlackDeepLink(team_id, channel_id) {
  if (!team_id || !channel_id) {
    return null;
  }
  return `slack://channel?team=${team_id}&id=${channel_id}`;
}

export function getHubspotLink(hubspot_portal_id, hubspot_contact_id, hubspot_meeting_id) {
  if (!hubspot_contact_id || !hubspot_meeting_id || !hubspot_portal_id) {
    return null;
  }
  return `https://app.hubspot.com/contacts/${hubspot_portal_id}/contact/${hubspot_contact_id}/?engagement=${hubspot_meeting_id}`;
}

export function searchHubspotContacts(search_string, callback) {
  getUserAccessToken(({ access }) => {
    csrfGet(BACKEND_URL + '/api/briefly/search-hubspot-contacts?q=' + search_string, {
      cache: 'no-cache',
      headers: {
        'Authorization': 'Bearer ' + access,
        'Content-Type': 'application/json'
      },
      referrerPolicy: 'no-referrer'
    }).then(response => response.json()).then(response => {
      callback(response);
    });
  });
}

export function selectHubspotContact(contact_id, transcript_id, callback) {
  getUserAccessToken(({ access }) => {
    csrfPost(BACKEND_URL + '/api/briefly/select-hubspot-contact', {
      cache: 'no-cache',
      headers: {
        'Authorization': 'Bearer ' + access,
        'Content-Type': 'application/json'
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        contact_id,
        transcript_id
      })
    }).then(response => response.json()).then(response => {
      callback(response);
    });
  });
}

export default {
  searchHubspotContacts,
  recallAiBotPause,
  recallAiBotResume,
  recallAiBotKickOut,
  groupBy,
  mapObject,
  formatDatetime,
  formatDate,
  groupConsecutiveByDeviceId,
  copyToClipboard,
  getUserAccessToken,
  getUserInfo,
  getTranscriptionAsText,
  generatePromptOutput,
  trackEvent,
  removeMeetingById,
  uploadTranscriptRequest,
  getUploadedTranscriptsRequest,
  sendZoomCodeToBackend,
  removeBullets,
  groupStringIntoArray,
  addColourToNames,
  getContextStrings,
  getContextStringsFromList,
  plural,
  mergeObjectsByMeetingId,
  highlightText,
  countWordSetMatches,
  setEmailNotificationsEnabled,
  setAttendeesEmailNotificationsEnabled,
  logOut,
  getRenewedUserInfo,
  removeZoomAuth,
  authCalendar,
  disconnectGoogleCalendar,
  disconnectGoogleDrive,
  authDrive,
  refreshUploadedTranscriptsRequest,
  backendMeetingDataToLocalMeetingData,
  removeBackendMeetingById,
  getGoogleDriveLink,
  sendTeamInvitation,
  openCheckoutSessionInNewTab,
  openCustomerPortalSessionInNewTab,
  confirmTeamInvitation,
  rejectTeamInvitation,
  removeMyselfFromTeam,
  removeTeamMember,
  checkIfStandardSummaryIsEligible,
  checkIfCustomSummaryIsEligible,
  deleteRejectedInvite,
  checkIfStandardSummaryWasMadeBefore,
  sendUserFeedback,
  isBrieflySuccess,
  csrfFetch,
  csrfPut,
  csrfGet,
  csrfPost,
  csrfPatch,
  csrfDelete,
  updateUserSettings,
  disconnectHubspot,
  disconnectSalesforce,
  hubspotCallback,
  hubspotInitiateOauth,
  setHubspotIntegrationIsActive,
  setHubspotGrowthOpportunitiesActive,
  setSalesforceIntegrationIsActive,
  setSalesforceGrowthOpportunitiesActive,
  updateTranscriptSettings,
  setupZoom,
  getNumberOfLines,
  calculateContentHeight,
  getHubspotLink
};
