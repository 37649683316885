import { MeetingMessage } from "../types/Transcript";

interface LanguagesFromMeetingResult {
  languages: string[];
  languagesPerSpeaker: Record<string, string[]>;
}

interface BlockSpeaker {
  name: string;
  deviceId: string;
  color: string;
}

class TranscriptBlock {
  speaker: BlockSpeaker | null;
  groupId: string;
  text: string[];
  languagesSpokenSet: Set<string> = new Set();

  constructor(speaker: BlockSpeaker | null, groupId: string) {
    this.speaker = speaker;
    this.groupId = groupId;
    this.text = [];
  }

  addLanguage(language: string) {
    this.languagesSpokenSet.add(language);
  }

  get languagesSpoken(): string[] {
    return Array.from(this.languagesSpokenSet);
  }
}

export const getLanguagesFromMeeting = (meetingMessages: MeetingMessage[]): LanguagesFromMeetingResult => {
  const languages = new Set<string>();
  const languagesPerSpeakerSet: Record<string, Set<string>> = {};

  for (const message of meetingMessages) {
    if ('event' in message) continue;

    const language = message.result.LanguageCode;
    languages.add(language);

    const speakerName = message.group.speaker.name;
    if (!languagesPerSpeakerSet[speakerName]) {
      languagesPerSpeakerSet[speakerName] = new Set();
    }
    languagesPerSpeakerSet[speakerName].add(language);
  }

  const languagesPerSpeaker: Record<string, string[]> = {};
  for (const [speaker, languagesSet] of Object.entries(languagesPerSpeakerSet)) {
    languagesPerSpeaker[speaker] = Array.from(languagesSet);
  }

  return {
    languages: Array.from(languages),
    languagesPerSpeaker,
  };
}

export const getUntranslatedTranscriptAsBlocks = (meetingMessages: MeetingMessage[]): TranscriptBlock[] => {
  const resultBlocks: TranscriptBlock[] = [];

  let currentGroupId: string | undefined = undefined;

  for (const message of meetingMessages) {
    if ('event' in message) continue;

    let foundSpeaker: BlockSpeaker | null = null;
    const { id: deviceId, name, color } = message.group.speaker;
    if (deviceId !== name) {
      foundSpeaker = { color, deviceId, name };
    }

    const groupId = message.group.id;
    const messageText = message.result.Alternatives[0].Transcript;

    if (!currentGroupId || currentGroupId !== groupId) {
      resultBlocks.push(new TranscriptBlock(foundSpeaker, groupId));
      currentGroupId = groupId;
    }

    resultBlocks[resultBlocks.length - 1].text.push(messageText);
    resultBlocks[resultBlocks.length - 1].addLanguage(message.result.LanguageCode);
  }

  return resultBlocks;
}

export const getUntranslatedTranscriptAsText = (meetingMessages: MeetingMessage[]): string => {
  const transcriptBlocks = getUntranslatedTranscriptAsBlocks(meetingMessages);

  return transcriptBlocks.map((block) => {
    if (!block.speaker) {
      return block.text.join(' ');
    }

    return `${block.speaker.name}:  ${block.text.join(' ')}`
  }).join('\n').trim();
}
