import styled from "styled-components";

const TextareaWrapper = styled.div`
  display: grid;
  width: 100%;
  min-height: 2rem;
  max-height: 8rem;
  overflow-y: auto;
  padding: 0.2rem 0.5rem;
  margin-right: 0.2rem;

  &::after {
    content: attr(data-textarea-content) " ";
    white-space: pre-wrap;
    visibility: hidden;
  }

  & > textarea, &::after {
    box-sizing: border-box;
    border: none;
    flex-grow: 1;
    width: 100%;
    font-family: inherit;
    flex-grow: 1;
    resize: none;
    overflow: hidden;
    font-size: 16px;
    grid-area: 1 / 1 / 2 / 2;
    outline: none;
  }
`;

interface GrowableTextAreaProps {
  className?: string;
  value?: string | number;
  onChange: React.ComponentProps<'textarea'>['onChange'];
  textareaProps?: React.ComponentProps<'textarea'>;
}

const GrowableTextArea = (props: GrowableTextAreaProps) => {
  const { className, textareaProps, onChange: onChangeProp, value } = props;

  return (
    <TextareaWrapper {...{className}} data-textarea-content={value}>
      <textarea {...{...textareaProps, onChange: onChangeProp, value} } />
    </TextareaWrapper>
  );
}

export default GrowableTextArea;