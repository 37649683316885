import React, { useState } from 'react';

import * as Dialog from '@radix-ui/react-dialog';
import { ThreeDots } from 'react-loader-spinner';

import style, { Container } from "./style";
import { apiPatch } from "../../../utils/genericUtils";
import FieldMappingContent from "./FieldMappingContent";
import ServiceContactForm, { SalesforceContact } from '../../ServiceContactForm';
import { Button } from '../../../theme';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { WorkflowRun } from '../MeetingTabWorkflowRun/types';

const HelpHeader = styled.h4`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  gap: 5px;
`;

interface Props {
  workflowRun: WorkflowRun,
  setWorkflowRun: (workflowRun: WorkflowRun) => void,
}

const MeetingTabSalesforce = ({ workflowRun, setWorkflowRun }: Props) => {
  const [openId, setOpenId] = useState<number>();
  const [isContactDialogOpen, setIsContactDialogOpen] = useState(false);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const { id, objectName, objectType, fieldMappings } = workflowRun;

  const handleOpenContactDialog: React.MouseEventHandler<HTMLButtonElement> = () => {
    setIsContactDialogOpen((bool) => !bool);
  };

  const updateWorkflowRun = async (body: Record<string, unknown>) => {
    setIsLoading(true);
    const { workflowRun: updatedWorkflowRun } = await apiPatch<WorkflowRun, 'workflowRun'>({
      path: `/workflow_runs/${id}`,
      body,
    }).finally(() => setIsLoading(false));

    setWorkflowRun(updatedWorkflowRun);
  }
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>, fieldMappingId: number) => {
    e.preventDefault();
    const body = Object.fromEntries(new FormData(e.currentTarget));

    updateWorkflowRun({ field_mapping_values: { [fieldMappingId]: body } });
  };

  const handleContactChange = async (salesforceContact: SalesforceContact) => {
    updateWorkflowRun({
      object_id: salesforceContact.Account.Id,
      object_name: salesforceContact.Account.Name,
      contact_ids: [salesforceContact.Id],
    });
  };

  const hasContact = objectName != null;

  return (
    <Container>
      <div style={style.header}>
        <span>{objectType}</span>
        <span style={style.headerRight}>
          {hasContact ? (
            <span>{objectName}</span>
          ) : (
            <span>{t('meeting.salesforce.no-contact.title', 'Briefly couldn’t detect a contact for this call')}</span>
          )}
          {/* TODO: Use Dialog component */}
          <Dialog.Root open={isContactDialogOpen} onOpenChange={setIsContactDialogOpen}>
            <Dialog.Trigger style={style.headerRightLink} onClick={handleOpenContactDialog}>{hasContact ? t('change', { ns: 'Actions', defaultValue: 'Change' }) : t('update', { ns: 'Actions', defaultValue: 'Update' })}</Dialog.Trigger>
            <Dialog.Portal>
              <Dialog.Overlay style={style.dialogOverlay}>
                <Dialog.Content style={style.dialogBox} onInteractOutside={(e) => e.preventDefault()}>
                  <ServiceContactForm onCancel={() => setIsContactDialogOpen(false)} onSuccess={handleContactChange} />
                </Dialog.Content>
              </Dialog.Overlay>
            </Dialog.Portal>
          </Dialog.Root>
        </span>
      </div>
      {hasContact && (
        <div style={style.itemList}>
          {fieldMappings.map((fieldMapping) => (
            <FieldMappingContent
              key={`field-mapping-${fieldMapping.id}`}
              fieldMapping={fieldMapping}
              isOpen={fieldMapping.id === openId}
              onSubmit={handleSubmit}
              onToggle={(id) => setOpenId(id)}
            />
          ))}
        </div>
      )}
      {!isLoading && !hasContact &&
        <HelpHeader>
          <span>{t('meeting.salesforce.no-contact.title', 'Briefly couldn’t detect a contact for this call')}</span>
          <Button inline link onClick={handleOpenContactDialog}>{t('update', { ns: 'Actions', defaultValue: 'Update' })}</Button>
        </HelpHeader>}
      {isLoading && <HelpHeader><ThreeDots
        height="10"
        width="80"
        radius="9"
        color="white"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        visible={true}
      /></HelpHeader>}
    </Container>
  );
};

export default MeetingTabSalesforce;
